import { Box, Table, Thead, Tbody, Th, Tr, Td, Flex, IconButton } from "@chakra-ui/react";
import {
  SortOrder,
  useLessonsQuery,
  useDeleteLessonMutation,
} from "../../generated/graphql";
import { FcRefresh, FcDatabase, FcPlus, FcEditImage } from "react-icons/fc";
import DeleteItem from "../../components/delete/DeleteItem";
import Loading from "../../components/loading/Loading";
import Error from "../../components/error/Error";
import PageLayout from "../../components/ui/PageLayout";
import { Link } from "react-router-dom";
import Description from "../../components/description/Description";

const All = () => {
  const [deleteLesson] = useDeleteLessonMutation();

  const { loading, error, data, refetch } = useLessonsQuery({
    variables: {
      orderBy: {
        createdAt: SortOrder.desc,
      },
    },
    fetchPolicy: "network-only",
  });
  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Error error={error} />;
  }

  const handleDelete = async (id: string) => {
    try {
      await deleteLesson({
        variables: {
          where: {
            id,
          },
        },
      });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <PageLayout>
      <Flex
        mb={6}
        justifyContent={"space-between"}
        alignItems="center"
        bgColor={"gray.100"}
        padding={2}
        rounded="md"
      >
        <Box fontSize={"18px"} fontWeight="bold">
          Lesson List
        </Box>
        <Flex experimental_spaceX={4}>
          <IconButton
            icon={<FcRefresh />}
            aria-label="Refetch Query"
            onClick={() => refetch()}
          />
          <IconButton
            to={`/lesson/create`}
            as={Link}
            icon={<FcPlus />}
            aria-label="Add Item"
            onClick={() => refetch()}
          />
        </Flex>
      </Flex>
      <Table>
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th>Descriptions</Th>
            <Th textAlign={"center"}>Contents</Th>
            <Th textAlign={"center"}>Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data?.lessons.map((lesson, i) => {
            return (
              <Tr key={i}>
                <Td>{i + 1}</Td>
                <Td minWidth={"300px"}>
                  <Description
                    field="lesson"
                    id={lesson.id}
                    descriptions={lesson.descriptions}
                    refetch={refetch}
                  />
                </Td>
                <Td textAlign={"center"}>
                  <IconButton
                    aria-label="Change Status"
                    as={Link}
                    to={`/content/Lesson/${lesson.id}`}
                    icon={<FcDatabase />}
                  />
                </Td>

                <Td>
                  <Flex justifyContent={"center"}>
                    <Box mx={2}>
                      <IconButton
                        aria-label="Edit"
                        icon={<FcEditImage />}
                        as={Link}
                        to={`/lesson/update/${lesson.id}`}
                      />
                    </Box>
                    <Box mx={2}>
                      <DeleteItem
                        id={lesson.id}
                        refetch={refetch}
                        title={`${lesson.descriptions[0].title}`}
                        handleDelete={handleDelete}
                      />
                    </Box>
                  </Flex>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </PageLayout>
  );
};

export default All;
