import React, { useCallback, useState, useEffect } from "react";
import {
  Flex,
  IconButton,
  Text,
  Image,
  SimpleGrid,
  GridItem,
  Spinner,
} from "@chakra-ui/react";
import { useDropzone } from "react-dropzone";
import { BsCardImage, BsTrash } from "react-icons/bs";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import { useUploadFileMutation } from "../../generated/graphql";
import axios from "axios";

interface SortablePhotoProps {
  deletePhoto: any;
  photo: any;
}
interface SortablePhotosProps {
  items: any[];
  deletePhoto: any;
}

const SortablePhoto = SortableElement(({ photo, deletePhoto }: SortablePhotoProps) => {
  return (
    <GridItem position="relative">
      <IconButton
        position="absolute"
        bottom="0"
        right="0"
        size="sm"
        fontSize="22px"
        colorScheme="red"
        aria-label="delete image"
        icon={<BsTrash />}
        onClick={() => deletePhoto(photo)}
      />
      <Image src={`${process.env.REACT_APP_CDN}thumbs/${photo}`} w="full" />
    </GridItem>
  );
});

const SortablePhotos = SortableContainer(
  ({ deletePhoto, items }: SortablePhotosProps) => {
    return (
      <SimpleGrid columns={8} gap={3} mb="4">
        {items
          .filter((item) => item.length !== 0)
          .map((photo, index) => {
            return (
              <SortablePhoto
                deletePhoto={deletePhoto}
                photo={photo}
                key={index}
                index={index}
              />
            );
          })}
      </SimpleGrid>
    );
  }
);

interface IUploadDropzone {
  images: string | string[];
  setImages: any;
  isSingle?: boolean;
}

const UploadDropzone: React.VFC<IUploadDropzone> = ({
  images,
  setImages,
  isSingle = false,
}) => {
  const [uploadPhoto, { loading: uploadLoading }] = useUploadFileMutation();

  const [addedPhotos, setAddedPhotos] = useState<string | string[]>(
    isSingle ? (images ? images : "") : images ? images : []
  );
  // const [addedPhotos, setAddedPhotos] = useState<string[]>(images ? images : []);
  const [change, setChange] = useState(1);

  const deletePhoto = (fileName: any) => {
    if (isSingle) {
      setAddedPhotos("");
    } else {
      const photos = (addedPhotos as string[]).filter((photo) => photo !== fileName);
      setAddedPhotos(() => [...photos]);
    }
  };

  useEffect(() => {
    setImages(addedPhotos);
  }, [addedPhotos, setAddedPhotos, setImages]);

  const onDrop = useCallback(
    async (acceptedFiles: any) => {
      await acceptedFiles.map(async (a: any) => {
        try {
          const result = await uploadPhoto({
            variables: {
              file: a,
            },
          });

          console.log("🌵💜🐢RESULT PHOTO", result.data?.uploadFile.file);

          if (isSingle) {
            setAddedPhotos(result.data?.uploadFile.file);
          } else {
            setAddedPhotos((prevState) => [
              ...(prevState as string[]),
              result.data?.uploadFile.file,
            ]);
          }

          setChange(change + Math.random());
        } catch (error) {
          console.log(error);
        }
      });
    },
    [change, isSingle, uploadPhoto]
  );

  const { isDragActive, getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    if (oldIndex === newIndex) return;
    if (!isSingle) {
      const newOrder = arrayMoveImmutable(addedPhotos as string[], oldIndex, newIndex);
      getOrder(newOrder);
    }
  };

  const getOrder = (e: any) => {
    setAddedPhotos(() => [...e]);
  };

  return (
    <>
      <SortablePhotos
        deletePhoto={deletePhoto}
        items={isSingle ? [addedPhotos as any] : (addedPhotos as string[])}
        axis="xy"
        onSortEnd={onSortEnd}
      />
      <Flex
        w="100%"
        h="150px"
        color="black"
        border="1px solid #ccc"
        justifyContent="center"
        alignItems="center"
        rounded="sm"
        _hover={{ cursor: "pointer" }}
        {...getRootProps()}
      >
        <Flex flexDirection="column" alignItems="center">
          {uploadLoading ? (
            <Flex alignItems="center" justifyContent="center">
              <Spinner size="lg" />
            </Flex>
          ) : isDragActive ? (
            <Text fontWeight="bold">Drop here</Text>
          ) : (
            <>
              <div>
                <BsCardImage size="36" />
              </div>
              <Text fontWeight="bold" fontSize="medium">
                Click here to add a picture or drag &amp; drop
              </Text>
            </>
          )}
        </Flex>
        {/* <form encType="multipart/form-data"> */}
        <input {...getInputProps({ multiple: !isSingle })} />
        {/* </form> */}
      </Flex>
    </>
  );
};

export default UploadDropzone;
